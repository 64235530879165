import { TimeSince } from "./api_types"
import { validate as uuidValidate } from "uuid"
import moment from "moment"

export const getTimeSince = (timeSince: TimeSince | undefined) => {
  if (!timeSince) {
    return "unknown minutes ago"
  }
  if (timeSince?.months_since) {
    return `${timeSince?.months_since} month${
      timeSince?.months_since === 1 ? "" : "s"
    } ago`
  }
  if (timeSince?.weeks_since) {
    return `${timeSince?.weeks_since} week${
      timeSince?.weeks_since === 1 ? "" : "s"
    } ago`
  }
  if (timeSince?.days_since) {
    return `${timeSince?.days_since} day${
      timeSince?.days_since === 1 ? "" : "s"
    } ago`
  }
  if (timeSince?.hours_since) {
    return `${timeSince?.hours_since} hour${
      timeSince?.hours_since === 1 ? "" : "s"
    } ago`
  }
  if (timeSince?.minutes_since) {
    return `${timeSince?.minutes_since} minute${
      timeSince?.minutes_since === 1 ? "" : "s"
    } ago`
  }
  return `Less than a minute ago`
}

export const getIdFromUrl = (typeOfId: string) => {
  if (typeof window !== "undefined") {
    const splitHref = window.location.href.split("/")
    const indexOfId = splitHref.findIndex(element => element === typeOfId)
    if (indexOfId === splitHref.length - 1) {
      return
    }

    const foundId = splitHref[indexOfId + 1]

    if (!uuidValidate(foundId)) {
      return
    }

    return foundId
  }
}

export const findLatestAskEvent = (ask_events: AskEvent[]) => {
  if (!ask_events.length) return
  console.log(ask_events)
  let latestMoment = ask_events[0].created_at
  let latestIndex = 0
  ask_events.forEach((ask_event, index) => {
    if (moment(ask_event.created_at).isAfter(latestMoment)) {
      latestMoment = ask_event.created_at
      latestIndex = index
    }
  })
  return ask_events[latestIndex]
}

export const findAskEventsByEventName = (
  ask_events: AskEvent[],
  eventName: string
) => {
  const filteredAskEvents = ask_events.filter(ask_event => {
    if (ask_event.event_name === eventName) return ask_event
  })
  return filteredAskEvents
}

const chars =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!@#$%^&*()_+`-=[]{}|;':,./<>?"

export function generateTestData() {
  let string
  for (let i = 0; i < 1024; i++) {
    string += chars[Math.floor(Math.random() * 60)]
  }
  return string
}
