import React, { createContext, useEffect, useState } from "react"
import { ApiClient } from "../services/api"
import axios from "axios"
import { LocalStorage } from "../services/local_storage"
import {
  Conversation,
  ConversationsResponse,
  GetAccountResponse,
} from "../services/api_types"
import ConversationList from "./shared/conversation_list"

interface User {
  data: GetAccountResponse | undefined
  resetUser: any
  signout: any
}

interface ConversationsWrapper {
  data: ConversationsResponse
  resetConversations: Function
}

const emptyUser: User = {
  data: {
    is_anonymous: true,
    account: undefined,
  },
  resetUser: () => {},
}

const emptyConversations: ConversationsWrapper = {
  data: {
    account_conversations: {},
    profile_conversations: {},
  },
  resetConversations: async () => {},
}

const emptyApiClient: ApiClient = new ApiClient(
  axios,
  process.env.GATSBY_API_URL
)

const UserContext: React.Context<User> = createContext(emptyUser)
const ApiContext: React.Context<ApiClient> = createContext(emptyApiClient)
const ConversationsContext: React.Context<ConversationsWrapper> = createContext(
  emptyConversations
)

export const resetConversations = async (
  apiClient: ApiClient,
  setConversations: Function
) => {
  const conversationsRes = await apiClient.getConversations()
  setConversations({
    data: conversationsRes,
    resetConversations: async () => {
      return resetConversations(apiClient, setConversations)
    },
  })
}

export const resetUser = async (apiClient: ApiClient, setUser: Function) => {
  const accountRes = await apiClient.getAccount()

  if (!!accountRes.error) {
    console.log("issue fetching account")
    return
  }

  LocalStorage.set("user", JSON.stringify(accountRes.data))
  setUser({
    data: accountRes.data,
    resetUser: () => {
      resetUser(apiClient, setUser)
    },
    signout: async () => {
      LocalStorage.unset("user")
      LocalStorage.unset("token")
      await apiClient.createAnonymousAccount()
    },
  })
}

const UserWrapper: React.FC<Array<React.FC>> = ({ children }) => {
  const [user, setUser] = useState<User>(emptyUser)
  const [apiClient, setApiClient] = useState<ApiClient>(emptyApiClient)
  const [conversations, setConversations] = useState<ConversationsWrapper>(
    emptyConversations
  )

  const userWrapperUseEffect = async () => {
    const realApiClient = new ApiClient(axios, process.env.GATSBY_API_URL)
    setApiClient(realApiClient)

    if (!apiClient.hasToken) {
      const createdAccount = await apiClient.createAnonymousAccount()
      if (createdAccount.data) {
        const user: User = {
          data: {
            is_anonymous: true,
            account: undefined,
          },
          resetUser: () => {
            resetUser(apiClient, setUser)
          },
          signout: async () => {
            LocalStorage.unset("user")
            LocalStorage.unset("token")
            await apiClient.createAnonymousAccount()
          },
        }
        LocalStorage.set("user", JSON.stringify(user.data))
        setUser(user)
      }

      console.log("issue creating anonymous account")
      return
    }

    console.log("user exists")
    const accountRes = await realApiClient.getAccount()
    console.log("accountRes", accountRes)
    const conversationsRes = await realApiClient.getConversations()
    console.log("conversationsRes", conversationsRes)
    if (!conversationsRes.error && conversationsRes.data) {
      console.log("conversationsRes", conversationsRes)
      setConversations({
        data: conversationsRes.data,
        resetConversations: async () => {
          return resetConversations(apiClient, setConversations)
        },
      })
    }
    if (!!accountRes.error) {
      console.log("issue fetching account")
      return
    }

    setUser({
      data: accountRes.data,
      resetUser: () => {
        resetUser(apiClient, setUser)
      },
      signout: async () => {
        LocalStorage.unset("user")
        LocalStorage.unset("token")
        await apiClient.createAnonymousAccount()
      },
    })
  }

  useEffect(() => {
    userWrapperUseEffect()
  }, [user.data?.is_anonymous, apiClient.hasToken])

  return (
    <ApiContext.Provider value={apiClient}>
      <UserContext.Provider value={user}>
        <ConversationsContext.Provider value={conversations}>
          {children}
        </ConversationsContext.Provider>
      </UserContext.Provider>
    </ApiContext.Provider>
  )
}

export { UserWrapper, UserContext, ApiContext, User, ConversationsContext }
