import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios"
import { generateTestData } from "./utils"
import { LocalStorage } from "./local_storage"
import {
  AskAccountInfo,
  AwsCreateUploadUrlResponse,
  ConversationsResponse,
  CreateAskEvent,
  CreateProfileResponse,
  CreateStripeLinkResponse,
  CreateUploadUrlRequest,
  CreateUploadUrlResponse,
  DeclineAskResponse,
  GetAccountResponse,
  GetAskResponse,
  LoginResponse,
  PayRequest,
  PayResponse,
  ProfileResponse,
  ProfileUpdateRequest,
  PublicProfileResponse,
  StripeOnboardingStatus,
  SubmitVideoRequest,
  SubmitVideoResponse,
  UpdateAccountRequest,
  UploadIntroVideoResponse,
  UploadProfilePhotoResponse,
  AttachLinksEvent,
  SubmitExpertAskDescription,
  SubmitExpertAskDescriptionWrapper,
  SubmitExpertAskDescriptionEvent,
  Attachments,
  ExpertInvite,
  ExpertInviteEvent,
  ExpertInviteResponse,
  Review,
} from "./api_types"
import {
  FacebookProfile,
  FacebookToken,
} from "../components/facebook_login/facebook_login"

export const ERROR_TYPES: { [key: string]: string } = {
  UNAUTHENTICATED_USER: "User must log in again to confirm authentication.",
  INVALID_REQUEST: "Request contains invalid data.",
  NO_PROFILE_FOR_SLUG: "No profile exists for this slug.",
  NO_PROFILE_FOR_ACCOUNT: "No profile exists for this account.",
  NO_PROFILE_FOR_ID: "No profile exists for the ID passed",
  INVALID_PROFILE_ID: "Invalid profile ID.",
  ALREADY_PAID: "Ask has already been paid.",
  ACCOUNT_CANNOT_ACCESS_PROFILE: "Account cannot access profile",
  PROFILE_CANNOT_ACCESS_ASK: "Profile is not authorized to access ask.",
  ACCOUNT_CANNOT_ACCESS_ASK: "Account is not authorized to access ask.",
  NO_STRIPE_ACCOUNT_EXISTS:
    "No Stripe account is associated with the given profile.",
  STRIPE_ACCOUNT_ALREADY_CREATED:
    "Stripe account has already been created for this profile.",
  STRIPE_ACCOUNT_NOT_ACTIVATED:
    "Strpie account has not been fully activated for payments,",
  PROFILE_IS_NOT_APPROVED:
    "Profile is not approved for this account. Cannot proceed with Stripe onboarding until profile is approved.",
  UNKNOWN_ERROR: "An unexpected error has been encountered.",
}

export interface ApiError {
  errorType: string
  errorMessage: string
  errorData: any
}

export interface ApiResponse<T> {
  data: T | undefined
  error: Error | undefined
}

export class ApiClient {
  axiosClient: AxiosInstance
  hasToken: boolean

  /**
   * Creates a Zipti API Client.
   * @param axiosClient axios client. e.g., import axios from "axios"
   * @param apiUrl the URL where Zipti API is hosted. e.g., https://api.zipti.com
   * @param token the jwt token to interact with the Zipti API. If undefined the client has not been authenticated.
   */
  constructor(axiosClient: AxiosInstance, apiUrl: string | undefined) {
    this.axiosClient = axiosClient
    this.axiosClient.defaults.baseURL = apiUrl
    this.axiosClient.defaults.headers.post["Access-Control-Allow-Origin"] = "*"
    if (LocalStorage.contains("token")) {
      this.hasToken = true
      this.setToken(ApiClient.getToken())
    } else {
      this.hasToken = false
    }
  }

  static localStorageHasToken = (): boolean => {
    if (typeof window !== "undefined") {
      const value = window.localStorage.getItem("token")
      if (!!value) {
        return true
      }
    }
    return false
  }

  /**
   * Sets token everywhere.
   * @param token jwt to authenticate and authorize with Zipti API
   */
  setToken(token: string) {
    LocalStorage.set("token", token)
    this.axiosClient.defaults.headers.common["Authorization"] = token
  }

  static getToken(): string {
    return LocalStorage.get("token")
  }

  async hasValidToken(): Promise<ApiResponse<boolean>> {
    try {
      const response = await this.axiosClient
        .get("/account/has_valid_token")
        .catch((err: any) => {
          throw new Error(ERROR_TYPES.UNAUTHENTICATED_USER)
        })

      if (response.status === 200) {
        return {
          data: true,
          error: undefined,
        }
      } else {
        throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
      }
    } catch (e) {
      return {
        data: false,
        error: e,
      }
    }
  }

  async createAnonymousAccount(): Promise<ApiResponse<boolean>> {
    try {
      const res: AxiosResponse<LoginResponse> = await this.axiosClient
        .post("/account/anonymous")
        .catch((err: any) => {
          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })

      if (res.status === 200) {
        const jwt: string = res.data.token
        this.setToken(jwt)
        return {
          data: true,
          error: undefined,
        }
      }

      return {
        data: false,
        error: new Error(ERROR_TYPES.UNKNOWN_ERROR),
      }
    } catch (e) {
      return {
        data: false,
        error: e,
      }
    }
  }

  async login(
    facebookProfile: FacebookProfile,
    token: FacebookToken
  ): Promise<ApiResponse<string>> {
    try {
      const res: AxiosResponse<LoginResponse> = await this.axiosClient
        .post("/login", {
          profile: facebookProfile,
          token: token,
        })
        .catch((err: any) => {
          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })

      if (res.status === 200) {
        const jwt: string = res.data.token
        LocalStorage.set("token", jwt)
        this.setToken(jwt)
        return {
          data: jwt,
          error: undefined,
        }
      } else {
        throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
      }
    } catch (e) {
      console.log(e.message)
      return {
        data: "",
        error: e,
      }
    }
  }

  async getPublicProfile(
    profileId: string
  ): Promise<ApiResponse<PublicProfileResponse>> {
    try {
      const res: AxiosResponse<PublicProfileResponse> = await this.axiosClient
        .get(`/profile/profile_id/${profileId}`)
        .catch((err: AxiosError) => {
          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })

      return {
        data: res.data,
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: e,
      }
    }
  }

  async getAccount(): Promise<ApiResponse<GetAccountResponse>> {
    try {
      const res: AxiosResponse<GetAccountResponse> = await this.axiosClient
        .get("/account")
        .catch((err: AxiosError) => {
          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })

      return {
        data: res.data,
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: e,
      }
    }
  }

  async getAccountById(
    account_id: string
  ): Promise<ApiResponse<GetAccountResponse>> {
    try {
      const res: AxiosResponse<GetAccountResponse> = await this.axiosClient
        .get(`/account/info/${account_id}`)
        .catch((err: AxiosError) => {
          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })

      return {
        data: res.data,
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: e,
      }
    }
  }

  async useUploadUrl(
    uploadUrl: AwsCreateUploadUrlResponse,
    fileToUpload: File
  ): Promise<ApiResponse<boolean>> {
    try {
      let formData = new FormData()
      Object.keys(uploadUrl.fields).forEach((field: string) => {
        if (!field) return
        formData.append(field, uploadUrl.fields[field])
      })
      // aws does not propagate "Conditions" into fields
      // so we have to do it manually
      formData.append("acl", "public-read")
      // file must come last
      // aws ignores all form fields after it
      formData.append("file", fileToUpload)
      const newAxios = axios.create()
      delete newAxios.defaults.headers.common["Authorization"]
      const res: AxiosResponse<any> = await newAxios
        .post(uploadUrl.url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch((err: AxiosError) => {
          console.log("uploadUrl error", err?.response?.data)
          console.log("res was", err.response)
          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })

      return {
        data: true,
        error: undefined,
      }
    } catch (e) {
      return {
        data: false,
        error: e,
      }
    }
  }

  async createUploadUrl(
    req: CreateUploadUrlRequest
  ): Promise<ApiResponse<CreateUploadUrlResponse | undefined>> {
    try {
      const res: AxiosResponse<CreateUploadUrlResponse> = await this.axiosClient
        .post("/upload_url", req)
        .catch((err: AxiosError) => {
          if (err.response?.status === 400) {
            throw new Error(ERROR_TYPES.INVALID_REQUEST)
          }
          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })

      return {
        data: res.data,
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: e,
      }
    }
  }

  async getStripeOnboardingStatus(): Promise<
    ApiResponse<StripeOnboardingStatus>
  > {
    try {
      const res: AxiosResponse<StripeOnboardingStatus> = await this.axiosClient
        .get("/account/stripe/onboarding")
        .catch((err: AxiosError) => {
          if (err?.response?.status === 403) {
            throw new Error(ERROR_TYPES.NO_STRIPE_ACCOUNT_EXISTS)
          }

          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })

      return {
        data: res.data,
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: e,
      }
    }
  }

  async registerStripeAccount(): Promise<ApiResponse<boolean>> {
    try {
      const res: AxiosResponse<LoginResponse> = await this.axiosClient
        .post("/account/stripe/register")
        .catch((err: any) => {
          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })

      if (res.status === 200) {
        return {
          data: true,
          error: undefined,
        }
      }

      return {
        data: false,
        error: new Error(ERROR_TYPES.UNKNOWN_ERROR),
      }
    } catch (e) {
      return {
        data: false,
        error: e,
      }
    }
  }

  async createStripeLink(): Promise<ApiResponse<CreateStripeLinkResponse>> {
    try {
      const res: AxiosResponse<CreateStripeLinkResponse> = await this.axiosClient
        .post("/account/stripe/link")
        .catch((err: AxiosError) => {
          if (err?.response?.status === 403) {
            throw new Error(ERROR_TYPES.NO_STRIPE_ACCOUNT_EXISTS)
          }

          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })

      return {
        data: res.data,
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: e,
      }
    }
  }

  async enableStripe(): Promise<ApiResponse<boolean>> {
    try {
      const res: AxiosResponse<object> = await this.axiosClient
        .post("/account/stripe/enable")
        .catch((err: AxiosError) => {
          if (err?.response?.status === 403) {
            throw new Error(ERROR_TYPES.NO_STRIPE_ACCOUNT_EXISTS)
          }

          if (err?.response?.status === 409) {
            throw new Error(ERROR_TYPES.STRIPE_ACCOUNT_NOT_ACTIVATED)
          }

          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })

      return {
        data: true,
        error: undefined,
      }
    } catch (e) {
      return {
        data: false,
        error: e,
      }
    }
  }

  async getProfile(): Promise<ApiResponse<ProfileResponse | undefined>> {
    try {
      const res: AxiosResponse<
        ProfileResponse | undefined
      > = await this.axiosClient
        .get("/profile/account")
        .catch((err: AxiosError) => {
          if (err.response?.status === 404) {
            throw new Error(ERROR_TYPES.NO_PROFILE_FOR_ACCOUNT)
          }
          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })

      if (res.status === 200) {
        return {
          data: res.data,
          error: undefined,
        }
      }

      return {
        data: undefined,
        error: new Error(ERROR_TYPES.UNKNOWN_ERROR),
      }
    } catch (e) {
      return {
        data: undefined,
        error: e,
      }
    }
  }

  async createProfile(): Promise<
    ApiResponse<CreateProfileResponse | undefined>
  > {
    try {
      const res: AxiosResponse<
        CreateProfileResponse | undefined
      > = await this.axiosClient.post("/profile").catch((err: AxiosError) => {
        throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
      })

      return {
        data: res.data,
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: e,
      }
    }
  }

  async updateAccount(
    req: UpdateAccountRequest
  ): Promise<ApiResponse<boolean | undefined>> {
    try {
      await this.axiosClient.put("/account", req).catch((err: AxiosError) => {
        if (err.response?.status === 400) {
          throw new Error(ERROR_TYPES.INVALID_REQUEST)
        }
        throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
      })

      return {
        data: true,
        error: undefined,
      }
    } catch (e) {
      return {
        data: false,
        error: e,
      }
    }
  }

  async updateProfile(
    req: ProfileUpdateRequest
  ): Promise<ApiResponse<boolean | undefined>> {
    try {
      await this.axiosClient.put("/profile", req).catch((err: AxiosError) => {
        if (err.response?.status === 400) {
          throw new Error(ERROR_TYPES.INVALID_REQUEST)
        }
        throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
      })

      return {
        data: true,
        error: undefined,
      }
    } catch (e) {
      return {
        data: false,
        error: e,
      }
    }
  }

  async markProfileActive(
    profile_id: string
  ): Promise<ApiResponse<boolean | undefined>> {
    try {
      await this.axiosClient
        .put(`/profile/${profile_id}/mark_is_active`)
        .catch((err: AxiosError) => {
          if (err.response?.status === 400) {
            throw new Error(ERROR_TYPES.INVALID_REQUEST)
          }
          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })

      return {
        data: true,
        error: undefined,
      }
    } catch (e) {
      return {
        data: false,
        error: e,
      }
    }
  }

  async getConversations(): Promise<
    ApiResponse<ConversationsResponse | undefined>
  > {
    try {
      const res: AxiosResponse<
        ConversationsResponse | undefined
      > = await this.axiosClient
        .get("/conversation")
        .catch((err: AxiosError) => {
          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })

      if (res.status === 200) {
        return {
          data: res.data,
          error: undefined,
        }
      }

      return {
        data: undefined,
        error: new Error(ERROR_TYPES.UNKNOWN_ERROR),
      }
    } catch (e) {
      return {
        data: undefined,
        error: e,
      }
    }
  }

  async getAsk(askId: string): Promise<ApiResponse<GetAskResponse>> {
    try {
      const res: AxiosResponse<GetAskResponse> = await this.axiosClient
        .get(`/ask/${askId}`)
        .catch((err: AxiosError) => {
          if (err.response?.status === 401) {
            throw new Error(ERROR_TYPES.ACCOUNT_CANNOT_ACCESS_ASK)
          }
          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })

      return {
        data: res.data,
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: e,
      }
    }
  }

  async getAskAccountInfo(askId: string): Promise<ApiResponse<AskAccountInfo>> {
    try {
      const res: AxiosResponse<AskAccountInfo> = await this.axiosClient
        .get(`/ask/${askId}/info`)
        .catch((err: AxiosError) => {
          if (err.response?.status === 401) {
            throw new Error(ERROR_TYPES.ACCOUNT_CANNOT_ACCESS_ASK)
          }
          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })

      return {
        data: res.data,
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: e,
      }
    }
  }

  async createAsk(profileId: string): Promise<ApiResponse<CreateAskEvent>> {
    try {
      const res: AxiosResponse<
        CreateAskEvent | undefined
      > = await this.axiosClient
        .post(`/ask/create_ask/${profileId}`)
        .catch((err: AxiosError) => {
          if (err.response?.status === 400) {
            throw new Error(ERROR_TYPES.INVALID_PROFILE_ID)
          }

          if (err.response?.status === 403) {
            throw new Error(ERROR_TYPES.NO_PROFILE_FOR_ID)
          }

          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })

      return {
        data: res.data,
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: e,
      }
    }
  }

  async submitAskVideo(
    askId: string,
    req: SubmitVideoRequest
  ): Promise<ApiResponse<SubmitVideoResponse | undefined>> {
    try {
      const res: AxiosResponse<
        SubmitVideoResponse | undefined
      > = await this.axiosClient
        .post(`/ask/${askId}/submit_ask_video`, req)
        .catch((err: AxiosError) => {
          if (err.response?.status === 403) {
            throw new Error(ERROR_TYPES.ACCOUNT_CANNOT_ACCESS_ASK)
          }

          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })

      return {
        data: res.data,
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: e,
      }
    }
  }

  async submitAnswerVideo(
    req: SubmitVideoRequest,
    askId: string
  ): Promise<ApiResponse<SubmitVideoResponse | undefined>> {
    try {
      const res: AxiosResponse<
        SubmitVideoResponse | undefined
      > = await this.axiosClient
        .post(`/ask/${askId}/submit_answer_video`, req)
        .catch((err: AxiosError) => {
          if (err.response?.status === 403) {
            throw new Error(ERROR_TYPES.ACCOUNT_CANNOT_ACCESS_ASK)
          }

          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })

      return {
        data: res.data,
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: e,
      }
    }
  }

  async submitClarifyingQuestionVideo(
    req: SubmitVideoRequest,
    askId: string
  ): Promise<ApiResponse<SubmitVideoResponse | undefined>> {
    try {
      const res: AxiosResponse<
        SubmitVideoResponse | undefined
      > = await this.axiosClient
        .post(`/ask/${askId}/submit_clarifying_question`, req)
        .catch((err: AxiosError) => {
          if (err.response?.status === 403) {
            throw new Error(ERROR_TYPES.ACCOUNT_CANNOT_ACCESS_ASK)
          }

          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })

      return {
        data: res.data,
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: e,
      }
    }
  }

  async submitClarifyingAnswerVideo(
    req: SubmitVideoRequest,
    askId: string
  ): Promise<ApiResponse<SubmitVideoResponse | undefined>> {
    try {
      const res: AxiosResponse<
        SubmitVideoResponse | undefined
      > = await this.axiosClient
        .post(`/ask/${askId}/submit_clarifying_answer`, req)
        .catch((err: AxiosError) => {
          if (err.response?.status === 403) {
            throw new Error(ERROR_TYPES.ACCOUNT_CANNOT_ACCESS_ASK)
          }

          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })

      return {
        data: res.data,
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: e,
      }
    }
  }

  async payAsk(
    askId: string,
    payRequest: PayRequest
  ): Promise<ApiResponse<PayResponse>> {
    try {
      const res: AxiosResponse<PayResponse> = await this.axiosClient
        .post(`/submit_payment_event/${askId}`, payRequest)
        .catch((err: AxiosError) => {
          if (err.response?.status === 400) {
            throw new Error(ERROR_TYPES.NO_STRIPE_ACCOUNT_EXISTS)
          }
          if (err.response?.status === 409) {
            throw new Error(ERROR_TYPES.ALREADY_PAID)
          }
          if (err.response?.status === 422) {
            throw new Error(ERROR_TYPES.STRIPE_ACCOUNT_NOT_ACTIVATED)
          }

          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })

      return {
        data: res.data,
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: e,
      }
    }
  }

  async declineAsk(askId: string): Promise<ApiResponse<DeclineAskResponse>> {
    try {
      const res: AxiosResponse<DeclineAskResponse> = await this.axiosClient
        .post(`/ask/${askId}/decline_ask`)
        .catch((err: AxiosError) => {
          if (err.response?.status === 401) {
            throw new Error(ERROR_TYPES.PROFILE_CANNOT_ACCESS_ASK)
          }
          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })

      return {
        data: res.data,
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: e,
      }
    }
  }

  async submitAttachLinks(
    ask_id: string,
    attachments: Attachments
  ): Promise<ApiResponse<AttachLinksEvent>> {
    try {
      const res: AxiosResponse<AttachLinksEvent> = await this.axiosClient
        .post(`/ask/${ask_id}/attach_links`, { attachments: attachments })
        .catch((err: AxiosError) => {
          // TODO: Create more accurate error response...
          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })
      return {
        data: res.data,
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: e,
      }
    }
  }

  async submitExpertAskDescription(
    ask_id: string,
    submit_expert_ask_description: SubmitExpertAskDescriptionWrapper
  ): Promise<ApiResponse<SubmitExpertAskDescriptionEvent>> {
    try {
      const res: AxiosResponse<SubmitExpertAskDescriptionEvent> = await this.axiosClient
        .post(
          `/ask/${ask_id}/submit_expert_ask_description`,
          submit_expert_ask_description
        )
        .catch((err: AxiosError) => {
          // TODO: Create more accurate error response...
          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })
      return {
        data: res.data,
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: e,
      }
    }
  }

  async activateExpertAccount(
    account_id: string,
    expert_invite: ExpertInvite
  ): Promise<ApiResponse<ExpertInviteResponse>> {
    try {
      const res: AxiosResponse<ExpertInviteResponse> = await this.axiosClient
        .put(`/expert_validation/${expert_invite}`, expert_invite)
        .catch((err: AxiosError) => {
          // TODO: Create more accurate error response...
          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })
      return {
        data: res.data,
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: e,
      }
    }
  }

  async createExpertInvite(): Promise<ApiResponse<string>> {
    try {
      const res: AxiosResponse<string> = await this.axiosClient
        .post(`/expert_invite`)
        .catch((err: AxiosError) => {
          // TODO: Create more accurate error response...
          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })
      return {
        data: res.data,
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: e,
      }
    }
  }

  async submitReview(
    ask_id: string,
    ask_review: Review
  ): Promise<ApiResponse<AttachLinksEvent>> {
    try {
      const res: AxiosResponse<AttachLinksEvent> = await this.axiosClient
        .post(`/ask/${ask_id}/submit_review`, { ask_review })
        .catch((err: AxiosError) => {
          // TODO: Create more accurate error response...
          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })
      return {
        data: res.data,
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: e,
      }
    }
  }

  async sendUpdateEmail(email: string): Promise<ApiResponse<string>> {
    try {
      const res: AxiosResponse<string> = await this.axiosClient
        .post(`/send_update_email`, { email })
        .catch((err: AxiosError) => {
          // TODO: Create more accurate error response...
          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })
      return {
        data: res.data,
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: e,
      }
    }
  }

  async sendUpdateEmailConfirmation(
    confirmation_code: string
  ): Promise<ApiResponse<string | undefined>> {
    try {
      const res: AxiosResponse<string> = await this.axiosClient
        .put(`/update_email`, { confirmation_code })
        .catch((err: AxiosError) => {
          // TODO: Create more accurate error response...
          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })
      return {
        data: res.data,
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: e,
      }
    }
  }

  async uploadProfilePhoto(
    image_url: string | undefined
  ): Promise<ApiResponse<string | undefined>> {
    try {
      const res: AxiosResponse<string> = await this.axiosClient
        .put(`/account/profile_image_url`, { image_url })
        .catch((err: AxiosError) => {
          // TODO: Create more accurate error response...
          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })
      return {
        data: res.data,
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: e,
      }
    }
  }

  async uploadProfileIntroVideo(
    video_url: string,
    profileId: string
  ): Promise<ApiResponse<string | undefined>> {
    try {
      const res: AxiosResponse<string> = await this.axiosClient
        .put(`/profile/${profileId}/profile_intro_video`, { video_url })
        .catch((err: AxiosError) => {
          // TODO: Create more accurate error response...
          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })
      return {
        data: res.data,
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: e,
      }
    }
  }

  async sayThanks(ask_id: string): Promise<ApiResponse<AttachLinksEvent>> {
    try {
      const res: AxiosResponse<AttachLinksEvent> = await this.axiosClient
        .post(`/ask/${ask_id}/say_thanks`, { ask_id })
        .catch((err: AxiosError) => {
          // TODO: Create more accurate error response...
          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })
      return {
        data: res.data,
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: e,
      }
    }
  }

  async submit_expert_seen_at(
    ask_event_id: string,
    ask_id: string
  ): Promise<ApiResponse<string>> {
    try {
      const res: AxiosResponse<string> = await this.axiosClient
        .post(`/ask/${ask_id}/expert_seen/${ask_event_id}`)
        .catch((err: AxiosError) => {
          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })
      return {
        data: res.data,
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: e,
      }
    }
  }

  async submit_account_seen_at(
    ask_event_id: string,
    ask_id: string
  ): Promise<ApiResponse<string>> {
    try {
      const res: AxiosResponse<string> = await this.axiosClient
        .post(`/ask/${ask_id}/account_seen/${ask_event_id}`)
        .catch((err: AxiosError) => {
          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })
      return {
        data: res.data,
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: e,
      }
    }
  }

  async cancel_payment(
    ask_id: string | undefined
  ): Promise<ApiResponse<string>> {
    try {
      const res: AxiosResponse<string> = await this.axiosClient
        .put(`/cancel_payment/${ask_id}`)
        .catch((err: AxiosError) => {
          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })
      return {
        data: res.data,
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: e,
      }
    }
  }

  async submitPayment(
    ask_id: string | undefined,
    payment_intent_id: string,
    handleServerResponse: any
  ): Promise<ApiResponse<string>> {
    try {
      fetch(`/pay/${ask_id}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ payment_intent_id: payment_intent_id }),
      })
        .then(function (confirmResult) {
          return confirmResult.json()
        })
        .then(handleServerResponse)
    } catch (e) {
      return {
        data: undefined,
        error: e,
      }
    }
  }

  async networkSpeedTest(fileSizeInBytes = 2000000) {
    let startTime = new Date().getTime()
    const defaultData = generateTestData()
    const data = JSON.stringify({ defaultData })
    let mbps
    try {
      const res: AxiosResponse<string> = await this.axiosClient
        .post(`/account/network_speed_test`, { data })
        .then(() => {
          const endTime = new Date().getTime()
          const duration = (endTime - startTime) / 1000
          const bitsLoaded = fileSizeInBytes * 125000
          const bps = (bitsLoaded / duration).toFixed(2)
          const kbps = (bps / 1000).toFixed(2)
          mbps = (kbps / 1000).toFixed(2)
        })
        .catch((err: AxiosError) => {
          throw new Error(ERROR_TYPES.UNKNOWN_ERROR)
        })
      console.log("MBPS", mbps)
      return {
        data: { res, mbps },
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: e,
      }
    }
  }
}
